.e-icons.e-edit:before {
  content: "\e891";
  display: none;
}

.generate-container{
  margin-top: "15px";
  display: flex;
  justify-content: left;
  align-items: center;
}

.checkbox{
  cursor: pointer;
  height: "19px";
  width: "19px";
}

#_dialog_wrapper_dialog-content{
  height:600px;
}

button.e-event-create {
  display: none;
}
